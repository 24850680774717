/* eslint-disable no-useless-escape */

import React, { useState, useEffect } from 'react';
import { Col, FormGroup, Row, Input, Label } from 'reactstrap';
import { useAuth, useFetch } from 'react-admin-base';
import { useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Breadcrumb } from 'react-admin-base-nazox';
import { useFormSubmit, CustomForm } from '../../Components/CustomForm';
import {
  AsyncPaginateInput,
  InputField,
  PhoneNumberInput,
  CategorySelectInput,
} from '../../Components/Input';
import {
  countryList,
  cityOfTurkey,
  currencyOptions,
} from '../../Util/Constants';
import { Alerts } from '../../Components/Alerts';
import { useUser } from '../../Components/UserProvider';
import NumberFormat from 'react-number-format';
import { taxNoCheck, validTCKN } from '../../Util/Validations';

const regex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const findCountryShortName = (value) => {
  const country = countryList.find((c) => c.countryEn === value);

  return country ? country.iso.toLowerCase() : '';
};

export const kobiOptions = [
  {
    enName: 'General',
    name: 'Genel',
    value: 0,
  },
  {
    enName: 'Micro',
    name: 'Mikro',
    value: 1,
  },
  {
    enName: 'Small',
    name: 'Küçük',
    value: 2,
  },
  {
    enName: 'Medium',
    name: 'Orta',
    value: 3,
  },
  {
    enName: 'Large',
    name: 'Büyük',
    value: 4,
  },
];

export const CustomerForm = () => {
  const params = useParams();
  const [customerLoading, setCustomerLoading] = useState(false);
  const id = params && params.id;
  const [done, setDone] = useState(false);
  const [api] = useAuth();
  const navigate = useNavigate();
  const isGroup = params['*']?.includes('group');
  const user = useUser();
  const userId = user && user.id;
  const [company] = useFetch('/company/get/user/' + userId);
  const companyCode = company && company.code;
  const validateOptions = (values) => {
    const error = {};

    if (!values.sector && companyCode !== 'global') {
      error.sector = 'REQUIRED';
    }
    if (!values.name) {
      error.name = 'REQUIRED';
    }
    if (values.name?.length > 150) {
      error.name = 'VALID150';
    }
    if (!values.countryName) {
      error.countryName = 'REQUIRED';
    }

    if (!values.cityName) {
      error.cityName = 'REQUIRED';
    } else if (values.cityName?.length > 50) {
      error.cityName = 'VALID50';
    }
    if (!values.fullAddress) {
      error.fullAddress = 'REQUIRED';
    } else if (values.fullAddress?.length > 50) {
      error.fullAddress = 'VALID50';
    }

    if (!values.email) {
      error.email = 'REQUIRED';
    } else if (regex.test(values.email) === false) {
      error.email = 'VALID';
    }
    if (!values.currency) {
      error.currency = 'REQUIRED';
    }

    if (!values.taxNumber && companyCode === 'aktifbank' && !isGroup) {
      error.taxNumber = 'REQUIRED';
    }
    if (
      values.countryName === 'Türkiye' &&
      values.taxNumber &&
      (values.companyType === 0
        ? !taxNoCheck(values.taxNumber)
        : !validTCKN(values.taxNumber))
    ) {
      error.taxNumber = 'VALID';
    }

    if (
      !values.tradeRegistrationNumber &&
      companyCode === 'aktifbank' &&
      !isGroup
    ) {
      error.tradeRegistrationNumber = 'REQUIRED';
    }

    if (
      !values.numberOfEmployees &&
      companyCode === 'aktifbank' &&
      !values.isGroupCompany
    ) {
      error.numberOfEmployees = 'REQUIRED';
    }

    if (
      values.kobiClass === '' &&
      companyCode === 'aktifbank' &&
      !values.isGroupCompany
    ) {
      error.kobiClass = 'REQUIRED';
    }

    if (values.groupId === 1) {
      error.groupId = 'REQUIRED';
    }

    return error;
  };

  const defaultNewState = {
    name: '',
    countryName: 'Türkiye',
    cityName: '',
    fullAddress: '',
    number: '',
    phoneNumber: '',
    email: '',
    currency: 'TRY',
    taxNumber: '',
    tradeRegistrationNumber: '',
    isGroupCompany: false,
    groupId: 0,
    sector: '',
    siteofAssets: 0,
    numberOfEmployees: '',
    kobiClass: '',
    companyType: 0,
  };

  useEffect(() => {
    if (params)
      setOptionState((prev) => ({ ...prev, isGroupCompany: isGroup }));
  }, [params]);

  const [countryShortName, setCountryShortName] = useState(
    findCountryShortName(defaultNewState.countryName)
  );

  const onSubmit = async (e) => {
    e.preventDefault();

    await api.tokenized.post('/customer/add', {
      ...optionsState,
      groupId: !optionsState.groupId ? 0 : optionsState.groupId.value,
      sector: optionsState.sector ? { code: optionsState.sector.code } : '',
      groupName: !optionsState.groupId ? '' : optionsState.groupId.label,
      kobiClass: optionsState.kobiClass ? optionsState.kobiClass : 0,
      siteofAssets:
        Number(
          optionsState.siteofAssets
            .toString()
            .replaceAll('.', '')
            .replace(',', '.')
        ) || 0,

      numberOfEmployees:
        Number(
          optionsState.numberOfEmployees
            .toString()
            .replaceAll('.', '')
            .replace(',', '.')
        ) || 0,
      countryName:
        optionsState.countryName === 'Türkiye'
          ? 'Turkey'
          : optionsState.countryName,
    });

    setDone(true);
  };

  const {
    loading,
    newSave,
    optionsState,
    handleChangeOptions,
    setOptionState,
    handleChangeSector,
    errors,
    setFirstSbumit,
    setError,
  } = useFormSubmit(onSubmit, validateOptions, defaultNewState);

  useEffect(() => {
    const fetchData = async () => {
      setCustomerLoading(true);
      await api.tokenized.get(`/customer/get/${id}`).then((a) => {
        setOptionState(
          a.data.phoneNumber && a.data.taxNumber
            ? {
                ...a.data,
                phoneNumber: a.data.phoneNumber.toString().replace('+', ''),
                number: a.data.number.toString().replace('+', ''),
                sector: a.data.sector?.code ? { ...a.data.sector } : null,
                groupId: { label: a.data.groupName, value: a.data.groupId },
              }
            : {
                ...a.data,
                sector: a.data.sector?.code ? { ...a.data.sector } : null,
                groupId: { label: a.data.groupName, value: a.data.groupId },
              }
        );
      });
      setCustomerLoading(false);
    };

    if (id) fetchData();
  }, [id]);

  const goPage = () => {
    if (id) {
      navigate('/customers');
    } else {
      navigate('/customer/add');
      setDone(false);
      setOptionState({ ...defaultNewState });
      setFirstSbumit(false);
    }
    document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  useEffect(() => {
    setCountryShortName(findCountryShortName(optionsState.countryName));
  }, [optionsState['countryName']]);

  useEffect(() => {
    if (optionsState['countryName']) {
      setOptionState((prev) => ({
        ...prev,
        ['phoneNumber']: '',
      }));
    }
  }, [optionsState['countryName']]);
  if (done) {
    setTimeout(() => {
      goPage();
    }, 2000);
  }
  React.useEffect(() => {
    const item = document.getElementsByClassName('react-select__control');
    if (item.length > 0)
      if (errors.validation && errors.validation.sector) {
        item[0].style.border = '1px solid #ff0000';
      } else {
        item[0].style.border = '1px solid #d2d2d2';
      }
  }, [errors.validation]);

  return (
    <>
      <Breadcrumb
        title={
          id ? (
            isGroup ? (
              <FormattedMessage id="EDIT_GROUP" />
            ) : (
              <FormattedMessage id="EDIT_CUSTOMER" />
            )
          ) : isGroup ? (
            <FormattedMessage id="ADD_NEW_GROUP" />
          ) : (
            <FormattedMessage id="ADD_NEW_CUSTOMER" />
          )
        }
        data={[
          {
            href: isGroup ? '/groups' : '/customers',
            name: isGroup ? (
              <FormattedMessage id="Groups" />
            ) : (
              <FormattedMessage id="CUSTOMERS" />
            ),
          },
          {
            href: id
              ? isGroup
                ? `/group/${id}/edit`
                : `/customer/${id}/edit`
              : isGroup
                ? '/group/add'
                : '/customer/add',
            name: id ? (
              isGroup ? (
                <FormattedMessage id="EDIT_GROUP" />
              ) : (
                <FormattedMessage id="EDIT_CUSTOMER" />
              )
            ) : isGroup ? (
              <FormattedMessage id="ADD_NEW_GROUP" />
            ) : (
              <FormattedMessage id="ADD_NEW_CUSTOMER" />
            ),
          },
        ]}
      >
        {!customerLoading ? (
          <CustomForm
            submit={newSave}
            errors={errors}
            loading={loading}
            done={done}
            id={
              id
                ? 'REDIRECT_CUSTOMERS'
                : isGroup
                  ? 'REDIRECT_ADD_CUSTOMERS'
                  : 'COMPLETED'
            }
            setError={setError}
            buttonText={<FormattedMessage id="ADD_CUSTOMER_SAVE" />}
          >
            {!optionsState.isGroupCompany && (
              <>
                <Row>
                  <Label>
                    <FormattedMessage id="TYPE_OF_COMPANY" />
                  </Label>
                </Row>
                <Row>
                  <Col sm="3">
                    <FormattedMessage id="TYPE_OF_COMPANY1" />
                    <InputField
                      tooltipId="TYPE_OF_COMPANY1"
                      style={{ marginLeft: '1em' }}
                      name="companyType"
                      value={0}
                      onChange={(e) =>
                        handleChangeOptions({
                          target: {
                            name: 'companyType',
                            value: e.target.checked ? 0 : 1,
                          },
                        })
                      }
                      type="checkbox"
                      checked={optionsState.companyType === 0}
                      tooltip={
                        <FormattedMessage id="TYPE_OF_COMPANY1_TOOLTIP" />
                      }
                    />
                  </Col>
                  <Col sm="5">
                    <FormattedMessage id="TYPE_OF_COMPANY2" />

                    <InputField
                      tooltipId="TYPE_OF_COMPANY2"
                      style={{ marginLeft: '1em' }}
                      name="companyType"
                      value={1}
                      onChange={(e) =>
                        handleChangeOptions({
                          target: {
                            name: 'companyType',
                            value: e.target.checked ? 1 : 0,
                          },
                        })
                      }
                      type="checkbox"
                      checked={optionsState.companyType === 1}
                      tooltip={
                        <FormattedMessage id="TYPE_OF_COMPANY2_TOOLTIP" />
                      }
                    />
                  </Col>
                </Row>

                <Row></Row>
              </>
            )}

            <FormGroup>
              <Row>
                <Col md={3}>
                  <InputField
                    name="name"
                    id="analysisCustomerId"
                    type="text"
                    onChange={handleChangeOptions}
                    validation={errors.validation}
                    value={optionsState['name']}
                  />
                </Col>
                <Col md={3}>
                  <InputField
                    type="select"
                    name="countryName"
                    id="COUNTRY_NAME"
                    onChange={handleChangeOptions}
                    validation={errors.validation}
                    value={optionsState.countryName}
                  >
                    {countryList.map((o, i) => (
                      <option value={o.countryEn} key={`${i}c`}>
                        {localStorage.frayjet_frayjet_language === 'en'
                          ? o.countryEn
                          : o.countryTr}
                      </option>
                    ))}
                  </InputField>
                </Col>
                <Col md={3}>
                  {optionsState.countryName === 'Türkiye' ? (
                    <InputField
                      name="cityName"
                      id="CITY_NAME"
                      type="select"
                      onChange={handleChangeOptions}
                      value={optionsState['cityName']}
                      validation={errors.validation}
                    >
                      <option value="">
                        <FormattedMessage id="NEW_ANL_PLACEHOLDER_TEXT" />
                      </option>
                      {cityOfTurkey.map((o) => (
                        <option value={o} key={`${o}c`}>
                          {o}
                        </option>
                      ))}
                    </InputField>
                  ) : (
                    <InputField
                      name="cityName"
                      id="CITY_NAME"
                      type="text"
                      onChange={handleChangeOptions}
                      value={optionsState['cityName']}
                      validation={errors.validation}
                    />
                  )}
                </Col>
                <Col md={3}>
                  <InputField
                    name="fullAddress"
                    id="ADDRESS"
                    onChange={handleChangeOptions}
                    value={optionsState['fullAddress']}
                    validation={errors.validation}
                    title={optionsState['fullAddress']}
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col md={3}>
                  <PhoneNumberInput
                    id="PHONE_NUMBER"
                    country={countryShortName}
                    value={optionsState['phoneNumber']}
                    onChange={(e) => handleChangeSector(e, 'phoneNumber')}
                  />
                </Col>
                <Col md={3}>
                  <InputField
                    name="number"
                    id="CUSTOMER_NUMBER"
                    onChange={(e) => {
                      if (!/\D/g.test(e.target.value)) handleChangeOptions(e);
                    }}
                    value={optionsState['number']}
                  />
                </Col>
                <Col md={3}>
                  <InputField
                    name="email"
                    id="ADD_USER_EMAIL"
                    onChange={handleChangeOptions}
                    value={optionsState['email']}
                    placeHolder="example@fray.com"
                    validation={errors.validation}
                  />
                </Col>
                {!optionsState.isGroupCompany && (
                  <Col md={3}>
                    <Label>
                      <FormattedMessage id="taxNo" /> / TCKN
                    </Label>
                    <InputField
                      name="taxNumber"
                      onChange={(e) => {
                        if (!/\D/g.test(e.target.value)) handleChangeOptions(e);
                      }}
                      value={optionsState.taxNumber}
                      style={{
                        WebkitAppearance: 'none',
                        border:
                          errors.validation &&
                          errors.validation.taxNumber &&
                          '1px solid #ff0000',
                      }}
                    />
                    {errors.validation && errors.validation.taxNumber && (
                      <p
                        style={{
                          color: 'red',
                          opacity: 0.6,
                          position: 'absolute',
                          fontSize: '12px',
                        }}
                      >
                        {<FormattedMessage id={'taxNo2'} />}{' '}
                        {
                          <FormattedMessage
                            id={errors.validation['taxNumber']}
                          />
                        }
                      </p>
                    )}
                  </Col>
                )}
              </Row>
            </FormGroup>

            {!optionsState.isGroupCompany && (
              <FormGroup>
                <Row>
                  <Col md={3}>
                    <InputField
                      name="tradeRegistrationNumber"
                      id="TRADE_REGISTRATION_NUMBER"
                      value={optionsState['tradeRegistrationNumber']}
                      onChange={(e) => {
                        if (/^[0-9\-]*$/.test(e.target.value))
                          handleChangeOptions(e);
                      }}
                      validation={errors.validation}
                    />
                  </Col>
                  <Col md={3}>
                    <InputField
                      name="currency"
                      id="CURRENCY"
                      onChange={handleChangeOptions}
                      value={optionsState['currency']}
                      type="select"
                    >
                      <option value="">
                        <FormattedMessage id="NEW_ANL_PLACEHOLDER_TEXT" />
                      </option>
                      {currencyOptions.map((o) => (
                        <option value={o} key={`${o}c`}>
                          {o}
                        </option>
                      ))}
                    </InputField>
                  </Col>{' '}
                  <Col md={3}>
                    {' '}
                    <Label>
                      <FormattedMessage id="NUMBER_OF_EMPLOYEES" />
                    </Label>
                    <NumberFormat
                      allowNegative={false}
                      name={'numberOfEmployees'}
                      className="form-control"
                      thousandSeparator={'.'}
                      decimalSeparator={false}
                      value={optionsState.numberOfEmployees}
                      onChange={(e) => handleChangeOptions(e)}
                      decimalScale={0}
                      isAllowed={(values) => {
                        const { formattedValue, floatValue } = values;
                        return (
                          formattedValue === '' ||
                          (floatValue > 0 && floatValue <= 1000000000)
                        );
                      }}
                      style={{
                        WebkitAppearance: 'none',
                        border:
                          errors.validation &&
                          errors.validation.numberOfEmployees &&
                          '1px solid #ff0000',
                      }}
                    />
                    {errors.validation &&
                      errors.validation.numberOfEmployees && (
                        <p
                          style={{
                            color: 'red',
                            opacity: 0.6,
                            position: 'absolute',
                            fontSize: '12px',
                          }}
                        >
                          {<FormattedMessage id={'NUMBER_OF_EMPLOYEES'} />}{' '}
                          {
                            <FormattedMessage
                              id={errors.validation['numberOfEmployees']}
                            />
                          }
                        </p>
                      )}
                  </Col>
                  <Col md={3}>
                    {' '}
                    <InputField
                      name="kobiClass"
                      id="KOBI_CLASS"
                      onChange={(e) =>
                        handleChangeOptions({
                          target: {
                            value: e.target.value ? Number(e.target.value) : '',
                            name: 'kobiClass',
                          },
                        })
                      }
                      value={optionsState['kobiClass']}
                      type="select"
                      validation={errors.validation}
                    >
                      <option value="">
                        <FormattedMessage id="NEW_ANL_PLACEHOLDER_TEXT" />
                      </option>
                      {kobiOptions.map((o, i) => (
                        <option value={o.value} key={i}>
                          {localStorage &&
                          localStorage.frayjet_frayjet_language === 'en'
                            ? o.enName
                            : o.name}
                        </option>
                      ))}
                    </InputField>
                  </Col>
                </Row>
              </FormGroup>
            )}

            <FormGroup>
              <Row>
                {company && company.code !== 'global' && (
                  <Col md={3}>
                    <CategorySelectInput
                      id="SECTOR_TYPE"
                      validation={errors.validation}
                      name={'sector'}
                      value={optionsState.sector || ''}
                      onChange={(e) => handleChangeSector(e, 'sector')}
                      tooltip={<FormattedMessage id="SECTOR_TYPE_INFO" />}
                    />
                  </Col>
                )}
                {!optionsState.isGroupCompany && (
                  <Col md={3}>
                    <Row>
                      <Col>
                        <Label>
                          <FormattedMessage id="GROUP?" />
                        </Label>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Label for="grupCheck">
                          <FormattedMessage id="YES" />
                        </Label>
                        <Input
                          style={{ marginLeft: '1em' }}
                          id="grupCheck"
                          name="grupCheck"
                          type="checkbox"
                          checked={
                            optionsState.groupId &&
                            optionsState.groupId.value !== 0
                          }
                          onChange={(e) => {
                            const checked = e.target.checked;
                            if (!checked)
                              setOptionState((prev) => ({
                                ...prev,
                                groupId: 0,
                              }));
                            else {
                              setOptionState((prev) => ({
                                ...prev,
                                groupId: 1,
                              }));
                            }
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                )}

                {optionsState.groupId && optionsState.groupId.value !== 0 ? (
                  <Col md={3}>
                    <AsyncPaginateInput
                      id="GROUP_TITLE"
                      name="groupId"
                      value={optionsState['groupId']}
                      onChange={(e) => {
                        handleChangeOptions(
                          e
                            ? e
                            : {
                                target: {
                                  name: 'groupId',
                                  value: null,
                                },
                              }
                        );
                        !e &&
                          setOptionState((prev) => ({
                            ...prev,
                            groupId: 1,
                          }));
                      }}
                      api={api}
                      url="/customer/get/paginate/groups"
                      label=""
                      validation={errors.validation}
                      groups
                    ></AsyncPaginateInput>
                  </Col>
                ) : (
                  ''
                )}
              </Row>
            </FormGroup>
          </CustomForm>
        ) : (
          <Alerts
            color="primary"
            className="fas fa-spin fa-spinner"
            messageId="LOADING"
            label=""
          />
        )}
      </Breadcrumb>
    </>
  );
};

export default CustomerForm;
