import React from 'react';
import { TaemedCustomerForm } from './TaemedCustomerForm';
import { CustomerForm } from './CustomerForm';

export const CustomerFormGeneral = ({ companyCode }) => {
  if (companyCode === 'ta3meed') {
    return <TaemedCustomerForm />;
  }

  return <CustomerForm />;
};
