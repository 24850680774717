import React, { useState, useEffect } from 'react';
import { Breadcrumb } from 'react-admin-base-nazox';
import { Card, CardBody } from 'reactstrap';
import { Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import { CustomerInfo } from './CustomerInfo';
import { CustomerAnalysisTable } from '../../Components/tables/customer-profile/CustomerAnalysisTable';
import { CustomerFinancialsContent } from '../../Components/customer-profile/CustomerFinancialsContent';
import { CustomerFilesContent } from '../../Components/customer-profile/CustomerFileContent';
import { CustomersTable_ } from '../../Components/tables/customer-profile/CustomersTable_';
import { useAuth } from 'react-admin-base';
import { Alerts } from '../../Components/Alerts';
import { NonFinancial } from '../NonFinancial/NonFinancial';
import NewsFeed from '../NewsFeed/NewsFeed';

export const CustomerProfile = () => {
  const [API] = useAuth();
  const { id } = useParams();
  const [customer, setCustomer] = useState({});
  const [customerLoading, setCustomerLoading] = useState(true);
  //const [queryId, setQueryId] = useState('');
  // const [newsFeedQueryId, setNewsFeedQueryId] = useState(''); // Yeni state
  //const [noDataAvailable, setNoDataAvailable] = useState(false);

  {
    /* const retrySubmitQuery = async (customerData, retries = 5, delay = 2000) => {
    let attempts = 0;
    while (attempts < retries) {
      try {
        const response = await axios.post(
          'https://external.jetscoring.com/nonFinancial/submitQueryFromTradeRegistry',
          {
            city: customerData.cityName || '',
            tradeRegistrationNumber: customerData.tradeRegistrationNumber || '',
            name: customerData.name || '',
            recordDate: customerData.recordDate || '',
            number: customerData.number || '',
            page: customerData.page || '',
            advType: customerData.advType || '',
            gazetteLink: customerData.gazetteLink || '',
          }
        );

        if (response.status === 200) {
          return { message: response.data.message, error: false };
        } else if (response.status === 400) {
          return { message: '', error: true };
        }
      } catch (error) {
        if (error.response && error.response.status === 500) {
          console.error(`Attempt ${attempts + 1} failed with 500. Retrying...`);
        } else {
          console.error('An error occurred:', error);
          throw new Error(
            'Failed to submit query due to an error: ' + error.message
          );
        }
      }
      attempts += 1;
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
    throw new Error('Failed to submit query after maximum retries.');
  };

  
  const retrySubmitNewsFeedQuery = async (
    customerData,
    retries = 5,
    delay = 2000
  ) => {
    let attempts = 0;
    while (attempts < retries) {
      try {
        const response = await axios.post(
          'http://localhost:2019/newsFeed/submitQueryForNewsFeed',
          {
            city: customerData.cityName || '',
            tradeRegistrationNumber: customerData.tradeRegistrationNumber || '',
            name: customerData.name || '',
            recordDate: customerData.recordDate || '',
            number: customerData.number || '',
            page: customerData.page || '',
            advType: customerData.advType || '',
            gazetteLink: customerData.gazetteLink || '',
          }
        );

        if (response.status === 200) {
          return { message: response.data.queryId, error: false };
        } else if (response.status === 400) {
          return { message: '', error: true };
        }
      } catch (error) {
        if (error.response && error.response.status === 500) {
          console.error(`Attempt ${attempts + 1} failed with 500. Retrying...`);
        } else {
          console.error('An error occurred:', error);
          throw new Error(
            'Failed to submit NewsFeed query due to an error: ' + error.message
          );
        }
      }
      attempts += 1;
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
    throw new Error('Failed to submit NewsFeed query after maximum retries.');
  };
  */
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: customerData } = await API.tokenized.get(
          `/customer/get/${id}`
        );

        setCustomer(
          customerData.phoneNumber && customerData.taxNumber
            ? {
                ...customerData,
                phoneNumber: customerData.phoneNumber
                  .toString()
                  .replace('+', ''),
                number: customerData.number.toString().replace('+', ''),
                sector: customerData.sector?.code
                  ? { ...customerData.sector }
                  : null,
                groupId: {
                  label: customerData.groupName,
                  value: customerData.groupId,
                },
              }
            : {
                ...customerData,
                sector: customerData.sector?.code
                  ? { ...customerData.sector }
                  : null,
                groupId: {
                  label: customerData.groupName,
                  value: customerData.groupId,
                },
              }
        );

        {
          /*   const { message: queryResponseMessage, error } =
          await retrySubmitQuery(customerData);
        if (error) {
          setNoDataAvailable(true);
        } else {
          setQueryId(queryResponseMessage);
        }

        const { message: newsFeedResponseMessage, error: newsFeedError } =
          await retrySubmitNewsFeedQuery(customerData);
        if (newsFeedError) {
          // Burada hata yönetimi yapılabilir
        } else {
          setNewsFeedQueryId(newsFeedResponseMessage); // newsFeed queryId'yi kaydet
        }
          */
        }
      } catch (error) {
        console.error('Failed to fetch customer or submit query:', error);
      } finally {
        setCustomerLoading(false);
      }
    };

    if (id) fetchData();
  }, [id]);

  return (
    <Breadcrumb
      title={'Müşteri Profil'}
      data={[
        {
          href: '/customers',
          name: <FormattedMessage id="CUSTOMERS" />,
        },
        {
          href: `/customer/${id}/profile`,
          name: 'Müşteri Profili',
        },
      ]}
    >
      {customerLoading ? (
        <Alerts
          color="primary"
          className="fas fa-spin fa-spinner"
          messageId="LOADING"
          label=""
        />
      ) : (
        <Card>
          <CardBody>
            {customer && <CustomerInfo customer={customer} />}
            <hr />
            <div>
              {customer && (
                <Tabs
                  id="controlled-tab-example0"
                  className="mb-3"
                  unmountOnExit={true}
                  mountOnEnter={true}
                  transition={false}
                >
                  {customer.isGroupCompany && (
                    <Tab
                      eventKey="customers"
                      title={<FormattedMessage id="GROUP_FIRMS" />}
                    >
                      <CustomersTable_ groupId={customer.id} />
                    </Tab>
                  )}

                  {!customer.isGroupCompany && (
                    <Tab
                      eventKey="customer-analysis"
                      title={<FormattedMessage id="CUSTOMER_ANALYSIS" />}
                    >
                      <CustomerAnalysisTable id={id} />
                    </Tab>
                  )}
                  {!customer.isGroupCompany && (
                    <Tab
                      eventKey="documents"
                      title={<FormattedMessage id="FILES" />}
                    >
                      <CustomerFilesContent id={id} />
                    </Tab>
                  )}

                  {!customer.isGroupCompany && (
                    <Tab
                      eventKey="financials"
                      title={<FormattedMessage id="FINANCIALS" />}
                    >
                      <CustomerFinancialsContent customerId={id} />
                    </Tab>
                  )}
                  {/*    {!customer.isGroupCompany && (
                    <Tab
                      eventKey="non-financial"
                      title={'Finansal Olmayan Analiz '}
                    >
                      <NonFinancial
                        queryId={queryId}
                        noDataAvailable={noDataAvailable}
                      />
                    </Tab>
                  )}
                  {!customer.isGroupCompany && (
                    <Tab eventKey="news-feed" title={'Haber Akışı '}>
                      <NewsFeed queryId={newsFeedQueryId} />
                    </Tab>
                  )}
                    */}
                </Tabs>
              )}
            </div>
          </CardBody>
        </Card>
      )}
    </Breadcrumb>
  );
};
