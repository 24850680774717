// @ts-nocheck
/* eslint-disable no-useless-escape */
/* eslint-disable no-compare-neg-zero */
import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from 'react-admin-base';
import { Tab, Tabs } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { Table, Button, Label } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alerts } from './../../Components/Alerts';
import { useFormSubmit, CustomForm } from '../../Components/CustomForm';
import NumberFormat from 'react-number-format';
import Swal from 'sweetalert2';
import { useFetch } from 'react-admin-base';
import { showSwal } from '../../Components/CustomForm';
import { Breadcrumb } from 'react-admin-base-nazox';
import { specifyColor, specifyFontWeight } from './../../Util/Constants';
import {
  AktifPasifDetectionTable,
  calculateNewAktifPasifDetection,
  findSumOfCalculatedDatas,
  title,
  boundaryNumber,
} from './../../Components/tables/edit/AktifPasifDetectionTable';
import { Status400_406ModalTable } from '../../Components/tables/edit/Status400_406ModalTable';
// functions should be reviewed. too many calculation.

const maxNumber = 999999999;

const buttonIsDisabled = (data) => {
  const data_ = data.find((e) => e.title === title);
  const values = data_.values;
  if (
    values.itemsFromCalculation.some(
      (e) => Math.abs(e.value) > boundaryNumber
    ) ||
    values.itemsFromDocument.some((e) => Math.abs(e.value) > boundaryNumber)
  )
    return true;
  return false;
};

const getClosesibleTitles = (data) => {
  const titles = {};

  data.forEach((e) => {
    if (!titles[e.name]) titles[e.name] = {};

    e.data.rowList.forEach((e2, i) => {
      if (e2.collapsableInfo) {
        if (
          (e2.collapsableInfo.length <
            e.data.rowList[i + 1]?.collapsableInfo?.length ||
            0) &&
          !titles[e.name][e2.collapsableInfo]
        ) {
          titles[e.name][e2.collapsableInfo] = true;
        }
      }
    });
  });
  return titles;
};

const restrictedWords = ['TOPLAM', 'Toplam', 'toplam'];

const checkTitle = (title) => {
  for (let i = 0; i < restrictedWords.length; i++) {
    if (title.includes(restrictedWords[i])) {
      return true;
    }
  }
  return false;
};
const onFocus = (e, value, setEditableInputClassName) => {
  if (value === 0) e.target.value = '';
};
const addZero = (e) => (e < 10 ? '0' + e : e);

const dateConverter = (d) => {
  const date = new Date(d);
  const day = addZero(date.getDate());
  const month = addZero(date.getMonth() + 1);
  return `${day}-${month}-${date.getFullYear()} ${
    d.split('T')[1].split('.')[0]
  }`;
};

const findUniqueIds = (arr) => {
  const data = [];
  arr.map((e) => !data.includes(e.userId) && data.push(e.userId));
  return data;
};

const findDifference = (arr) => {
  if (!arr) return [];
  const differenceArray = arr[arr.length - 1].modifiedData.map((d, i) => {
    return arr[0].modifiedData[i]?.value - d.value;
  });
  return differenceArray;
};

const findUniqueData = (arr) => {
  const data = [];
  if (!arr) return data;
  let x = 0;

  for (var i = 0; i < arr.length - 1; i++) {
    if (
      JSON.stringify(arr[i].modifiedData) !==
      JSON.stringify(arr[i + 1].modifiedData)
    ) {
      x++;
      data.push(arr[i]);
    }
  }

  if (x === 0) return [];
  data.push(arr[arr.length - 1]);
  return data;
};

const isDisabled = (title, analyzeType) => {
  if (
    analyzeType === 9 &&
    (checkTitle(title) ||
      ['Ana Ortaklığa Ait Özkaynaklar', 'ARA TOPLAM'].includes(title))
  )
    return true;
  if (
    [7, 1, 15, 14].includes(analyzeType) &&
    isNaN(parseInt(title.charAt(0))) &&
    title !== 'KDönemKarıVergiveDiğerYasalYükümlülük_b5_6'
  )
    return true;
  if (
    [9, 10].includes(analyzeType) &&
    [
      'VARLIKLAR',
      'Dönen Varlıklar',
      'Duran Varlıklar',
      'Kısa Vadeli Yükümlülükler',
      'Uzun Vadeli Yükümlülükler',
      'Özkaynaklar',
      'KAYNAKLAR',
      'TİCARİ FAALİYETLERDEN BRÜT KAR (ZARAR)',
      'FİNANS SEKTÖRÜ FAALİYETLERİNDEN BRÜT KAR (ZARAR)',
      'BRÜT KAR (ZARAR)',
      'ESAS FAALİYET KARI (ZARARI)',
      'FİNANSMAN GELİRİ (GİDERİ) ÖNCESİ FAALİYET KARI (ZARARI)',
      'SÜRDÜRÜLEN FAALİYETLER VERGİ ÖNCESİ KARI (ZARARI)',
      'DÖNEM KARI (ZARARI)',
    ].includes(title)
  )
    return true;
  return false;
};

// const findDif = (arr) => {
//   const difference = arr.map((e) =>
//     e.data.rowList.map((d) => {
//       const data = [];
//       if (!d.trackingInfo[0]?.trackingInfoRow) return data;
//       d.trackingInfo[0]?.trackingInfoRow;
//     })
//   );
//   return;
// };

export const AktifAnalysisEdit = () => {
  const [api] = useAuth();
  const { id } = useParams();
  const intl = useIntl();
  const _id = id && window.atob(id);
  const navigate = useNavigate();
  const [user] = useFetch('/user/me');
  const [aktifPasifDetection, , , update] = useFetch(
    '/tables/statement/edit/status/' + _id
  );
  const [analyze, analyzeTypeLoading] = useFetch('/analyze/get/' + _id);
  const analyzeType = analyze && analyze.providerType;
  const [statement, setStatement] = useState([]);
  const [_statement, _setStatement] = useState([]);
  const [done, setDone] = useState(false);
  const [modal, setModal] = useState([]);
  const [names, setNames] = useState({});
  const [notes, setNotes] = useState([]);
  const [openColumns, setOpenColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [calculatorDatas, setCalculatorDatas] = useState({});
  const [titlesForVisibility, setTitlesForVisibility] = useState({});
  const [errorInfo, setErrorInfo] = useState(null);
  const [changedDatas, setChangedDatas] = useState({});
  const [editableInputClassName, setEditableInputClassName] = useState({});
  const findChangedIndexAndCollapsibleInfo = (name, title, year) => {
    const nameIndex = statement.findIndex((s) => s.name === name);
    const titleIndex = statement[nameIndex].data.rowList.findIndex(
      (s) => s.uniqueName === title
    );
    const yearIndex = statement[nameIndex].data.rowList[
      titleIndex
    ].values.findIndex((s) => s.year === Number(year));

    return {
      nameIndex,
      titleIndex,
      yearIndex,
      collapsableInfo:
        statement[nameIndex].data.rowList[titleIndex].collapsableInfo,
    };
  };

  const onBlur = (e, calc) => {
    const {
      dataset: { name },
      dataset: { title },
      dataset: { year },
    } = e.currentTarget;

    setEditableInputClassName((prev) => ({
      ...prev,
      [`${title}/${year}`]: { textAlign: 'right' },
    }));
    if (!e.target.value) {
      const { nameIndex, titleIndex, yearIndex, collapsableInfo } =
        findChangedIndexAndCollapsibleInfo(name, title, year);
      if (calc) {
        setCalculatorDatas((prev) => ({
          ...prev,

          [title]: {
            nameIndex: nameIndex,
            titleIndex: titleIndex,
            collapsableInfo,
            ...prev[title],
            [year]: 0,
          },
        }));

        // setChangedDatas((prev) => ({
        //   ...prev,

        //   [title]: {
        //     nameIndex: nameIndex,
        //     titleIndex: titleIndex,
        //     collapsableInfo,
        //     ...prev[title],
        //     [year]: 0,
        //   },
        // }));
      } else {
        let newState = JSON.parse(JSON.stringify(statement));

        newState[nameIndex].data.rowList[titleIndex].values[yearIndex].value =
          0;

        // const differences = findDifference(
        //   statement[nameIndex].data.rowList[titleIndex].trackingInfo[0]
        //     ?.trackingInfoRow
        // );

        // const changedData =
        //   0 -
        //   _statement[nameIndex].data.rowList[titleIndex].values[yearIndex]
        //     .value -
        //   differences[yearIndex];

        // newState[nameIndex].data.rowList.map((e, i) => {
        //   if (
        //     collapsableInfo.startsWith(e.collapsableInfo) &&
        //     collapsableInfo !== e.collapsableInfo
        //   ) {
        //     const index = e.values.findIndex((e) => e.year === Number(year));
        //     const differences = findDifference(
        //       statement[nameIndex].data.rowList[i].trackingInfo[0]
        //         ?.trackingInfoRow
        //     );
        //     e.values[index].value =
        //       _statement[nameIndex].data.rowList[i].values[index].value +
        //       changedData +
        //       differences[index];
        //   }
        // });
        setStatement(newState);
      }
    }
  };

  const toggle = (key, datas) => {
    const uniqueIds = findUniqueIds(datas);
    findNames(uniqueIds);

    setModal((prev) =>
      prev.includes(key) ? prev.filter((e) => e !== key) : [...prev, key]
    );
  };

  const handleCalculatorDatas = (e) => {
    const {
      dataset: { title },
      dataset: { year },
      dataset: { name },

      value,
    } = e.currentTarget;
    const { nameIndex, titleIndex, yearIndex, collapsableInfo } =
      findChangedIndexAndCollapsibleInfo(name, title, year);
    const newValue =
      value === '-' || value === ','
        ? value
        : +value.replace(/\./g, '').replace(/\,/g, '.');

    setCalculatorDatas((prev) => ({
      ...prev,
      [title]: {
        ...prev[title],

        collapsableInfo,

        [year]: newValue,
      },
    }));
    const differences = findDifference(
      statement[nameIndex].data.rowList[titleIndex].trackingInfo[0]
        ?.trackingInfoRow
    );
    setChangedDatas((prev) => ({
      ...prev,
      [title]: {
        ...prev[title],

        collapsableInfo,

        [year]: newValue - differences[yearIndex],
      },
    }));

    const newStatement = JSON.parse(JSON.stringify(statement));

    const value2 =
      _statement[nameIndex].data.rowList[titleIndex].values[yearIndex].value +
      newValue;

    newStatement[nameIndex].data.rowList[titleIndex].values[yearIndex].value =
      value2;

    setStatement(newStatement);
    let newChangedNotes = [...optionsState];
    !newChangedNotes.includes(title) &&
      setOptionState((prev) => [...prev, title]);
  };

  const handleChangeAktifEdit = (e) => {
    //should be review. what was i thinking when i wrote this
    const {
      dataset: { name },
      dataset: { title },
      dataset: { year },

      value,
    } = e.currentTarget;

    const { nameIndex, titleIndex, yearIndex, collapsableInfo } =
      findChangedIndexAndCollapsibleInfo(name, title, year);

    let newState = JSON.parse(JSON.stringify(statement));
    const newValue =
      value === '-' || value === ','
        ? value
        : +value.replace(/\./g, '').replace(/\,/g, '.');

    const differences = findDifference(
      statement[nameIndex].data.rowList[titleIndex].trackingInfo[0]
        ?.trackingInfoRow
    );

    const changedData =
      newValue -
      _statement[nameIndex].data.rowList[titleIndex].values[yearIndex].value -
      differences[yearIndex];

    setChangedDatas((prev) => ({
      ...prev,

      [title]: {
        ...prev[title],

        collapsableInfo,

        [year]: changedData,
      },
    }));

    setCalculatorDatas((prev) => ({
      ...prev,

      [title]: {
        ...prev[title],

        collapsableInfo,

        [year]:
          newValue -
          _statement[nameIndex].data.rowList[titleIndex].values[yearIndex]
            .value,
      },
    }));

    let newChangedNotes = [...optionsState];
    !newChangedNotes.includes(title) &&
      setOptionState((prev) => [...prev, title]);
    newState[nameIndex].data.rowList[titleIndex].values[yearIndex].value =
      newValue;

    setStatement(newState);
  };

  const showTextSwal = async (props) =>
    await Swal.fire({
      input: 'textarea',

      inputPlaceholder: intl.formatMessage({
        id: 'TYPE_COMMENT',
      }),

      inputValue: props.commentText || '',
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({
        id: 'ADD_USER_SAVE',
      }),
      cancelButtonText: intl.formatMessage({
        id: 'CANCEL',
      }),

      customClass: {
        actions: 'margin-buttons',
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-secondary',
        input: 'input-class',
      },
      confirmButtonColor: 'green',
      cancelButtonColor: 'red',
    }).then((e) => {
      if (e.isConfirmed) {
        const newNotes = [...notes];

        if (newNotes.length === 0) {
          newNotes.push({ ...props, ['commentText']: e.value });
        } else {
          const index = newNotes.findIndex(
            (n) => n.uniqueRowName === props.uniqueRowName
          );
          if (index === -1) {
            newNotes.push({ ...props, ['commentText']: e.value });
          } else newNotes[index].commentText = e.value;
        }
        if (optionsState.includes(props.uniqueRowName))
          setOptionState((prev) =>
            prev.filter((e) => e !== props.uniqueRowName)
          );
        setNotes(newNotes);

        Swal.fire({
          icon: 'success',
          customClass: 'swal-class',
          background: '#90EE90',
          timer: 1000,
          showConfirmButton: false,
          backdrop: false,
        });
      }
    });
  const fetchDatas = () => {
    setErrorInfo(null);
    api.tokenized.get('/tables/statement/edit/' + window.atob(id)).then((a) => {
      const data_ = JSON.parse(JSON.stringify(a.data));
      const titles = getClosesibleTitles(data_);
      setTitlesForVisibility(titles);

      setStatement(data_);

      const newCalcualtor = JSON.parse(JSON.stringify(calculatorDatas));
      const newCalcualtorForChanges = JSON.parse(
        JSON.stringify(calculatorDatas)
      );

      a.data.map((e) => {
        e.data.rowList.map((e2) => {
          const years = e2.values.map((e3) => e3.year);
          const differences = findDifference(
            e2.trackingInfo[0]?.trackingInfoRow
          );

          newCalcualtorForChanges[e2.uniqueName] = {
            [years[0]]: 0,
            [years[1]]: 0,
            [years[2]]: 0,
            collapsableInfo: e2.collapsableInfo,
            isDisabled: isDisabled(e2.title, analyzeType),
          };

          newCalcualtor[e2.uniqueName] = {
            [years[0]]: differences[0],
            [years[1]]: differences[1],
            [years[2]]: differences[2],
            collapsableInfo: e2.collapsableInfo,
            isDisabled: isDisabled(e2.title, analyzeType),
          };
        });
      });
      setChangedDatas(newCalcualtorForChanges);
      setCalculatorDatas(newCalcualtor);
    });

    api.tokenized
      .get('/tables/statement/edit/constant/' + window.atob(id))
      .then((a) => {
        _setStatement(JSON.parse(JSON.stringify(a.data)));
      });

    api.tokenized
      .get('/tables/comments/get/' + window.atob(id))
      .then(
        (a) =>
          a.data &&
          a.data.requestModel &&
          setNotes(a.data && a.data.requestModel)
      );
    fetchData();
  };
  const fetchData = async () => {
    try {
      update();

      const result = await api.tokenized.get(
        '/tables/statement/edit/check/' + window.atob(id)
      );

      if (result.status === 200) {
        console.log('result: ', result);
      }
    } catch (e) {
      if (e.message && e.response.data.message) {
        setErrorInfo({
          message: e.response.data.message,
          data: e.response.data.requestModel,
          status: e.response.status,
        });
      }
    } finally {
      console.log('test');
    }
  };

  useEffect(() => {
    analyzeType && fetchDatas();
  }, [analyzeType]);

  const findNames = async (arr) => {
    await arr.map((id) => {
      if (!names[id])
        api.tokenized.get('/user/company/' + id).then((a) => {
          setNames((prev) => ({
            ...prev,
            [id]: a.data.first_name + ' ' + a.data.last_name,
          }));
        });
    });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await api.tokenized.post(
        '/tables/statement/edit/' + window.atob(id),
        statement
      );
      if (result.status === 200) {
        await api.tokenized.post('/tables/comments/add/', notes);
        setDone(true);
      }
    } catch (e) {
      if (e.message && e.response.data.message) {
        const errorMessage = e.response.data.message;

        const newCalcualtor = JSON.parse(JSON.stringify(calculatorDatas));

        e.response.data.requestModel.map((e) => {
          e.data.rowList.map((e2) => {
            const years = e2.values.map((e3) => e3.year);
            const differences = findDifference(
              e2.trackingInfo[0]?.trackingInfoRow
            );
            newCalcualtor[e2.uniqueName] = {
              [years[0]]: differences[0],
              [years[1]]: differences[1],
              [years[2]]: differences[2],
              collapsableInfo: e2.collapsableInfo,
              isDisabled: isDisabled(e2.title, analyzeType),
            };
          });
        });

        setStatement(JSON.parse(JSON.stringify(e.response.data.requestModel)));
        setCalculatorDatas(newCalcualtor);
        update();
        showSwal(
          `<p>${
            typeof errorMessage === 'string'
              ? ''
              : localStorage.frayjet_frayjet_language === 'en'
                ? errorMessage.eng
                : errorMessage.tr
          }</p>`,
          '',
          intl
        );
      }
    }

    setChangedDatas([]);

    setLoading(false);
  };
  const validateOptions = (values) => {
    const error = {};
    values.map((e) => (error[e] = 'REQUIRED'));
    return error;
  };

  //sadece notları useFormSubmite gönderiyoruz. diğer statelerde bu klasörde. Buranın değişmesi gerekiyor artık.
  const { newSave, errors, setError, optionsState, setOptionState } =
    useFormSubmit(onSubmit, validateOptions, []);

  const goPage = () => {
    navigate('/report/' + id + '/summary');

    document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  if (done) {
    setTimeout(() => {
      goPage();
    }, 2000);
  }

  const handleClickOpenCloseTitles = (name, info) => {
    if (!info || !name) return;

    setTitlesForVisibility((prev) => {
      if (!prev[name]) prev[name] = {};
      prev[name][info] = !prev[name][info];
      return { ...prev };
    });
  };

  const isTitleVisible = (name, info) => {
    if (!info || !name) return true;

    return !Object.entries(titlesForVisibility[name]).some((e) => {
      return (
        info.indexOf(e[0]) !== -1 &&
        info[info.indexOf(e[0]) + e[0].length] === '.' &&
        e[0] !== info &&
        e[1] === true
      );
    });
  };

  const findLessDotCountDiffractionItem = (name) => {
    if (!titlesForVisibility[name]) return;

    let leastDotNumber = maxNumber;
    Object.entries(titlesForVisibility[name]).map((e) => {
      if (e[1] === true) {
        const dotNumber = e[0].split('.').length;
        if (dotNumber < leastDotNumber) {
          leastDotNumber = dotNumber;
        }
      }
    });

    return leastDotNumber;
  };

  const findMaxDotCountDiffractionItem = (name) => {
    let maxDotNumber = 0;
    Object.entries(titlesForVisibility[name]).map((e) => {
      const dotNumber = e[0].split('.').length;
      if (dotNumber > maxDotNumber) {
        maxDotNumber = dotNumber;
      }
    });

    return maxDotNumber;
  };

  const dotCountsForDiffractionItems = (name) => {
    if (!titlesForVisibility[name]) return [];
    const items = [];
    Object.entries(titlesForVisibility[name]).map((e) => {
      const dotNumber = e[0].split('.').length;
      if (!items.includes(dotNumber)) {
        items.push(dotNumber);
      }
    });

    return items;
  };

  const handleDiffractionForTitles = (name) => {
    const leastDotNumber = findLessDotCountDiffractionItem(name);
    if (leastDotNumber === maxNumber)
      setTitlesForVisibility(getClosesibleTitles(statement));
    else {
      let titlesForVisibility_ = JSON.parse(
        JSON.stringify(titlesForVisibility)
      );
      Object.entries(titlesForVisibility[name]).map((e) => {
        if (e[0].split('.').length === leastDotNumber) {
          titlesForVisibility_[name][e[0]] = false;
        }
      });
      setTitlesForVisibility(titlesForVisibility_);
    }
  };

  const additionalItemData =
    statement && statement.find((e) => e.name === 'Additional Items');
  const tableItems = (row, i) => {
    return (
      row && (
        <div className="table-container">
          <Table
            id={i + '-to-xls'}
            bordered
            key={i}
            size="sm"
            responsive
            style={{ margin: 0 }}
          >
            <thead className="thead">
              <tr style={{ border: '1px solid black' }}>
                <th
                  className="text-center th-first"
                  style={{
                    minWidth: '250px',
                    width: '400px',
                  }}
                >
                  <FormattedMessage id="ACCOUND_CODE" /> |{' '}
                  <FormattedMessage id="SUBTAB_4_1_TITLE1" />
                </th>
                {row?.data?.rowList[0]?.values.map((a, z) => (
                  <React.Fragment key={z}>
                    <th className="text-center">
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {a.period}{' '}
                        <Button
                          outline={true}
                          color="secondary"
                          onClick={() =>
                            setOpenColumns((prev) =>
                              prev.includes(a.year)
                                ? prev.filter((e) => e !== a.year)
                                : [...prev, a.year]
                            )
                          }
                          style={{
                            width: '10px',
                            height: '14px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginLeft: '5px',
                            border: 0,
                          }}
                        >
                          <i className="fa fa-pencil-alt" />{' '}
                        </Button>
                      </div>
                      <hr style={{ margin: '0.5em' }} />
                      <tr
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-around',
                        }}
                      >
                        <b
                          style={{
                            fontSize: '12px',
                          }}
                        >
                          Belgeden Aktarılan
                        </b>
                        {openColumns.includes(a.year) && (
                          <>
                            <b
                              style={{
                                fontSize: '12px',
                              }}
                            >
                              Ekleme/Çıkartma
                            </b>

                            <b
                              style={{
                                fontSize: '12px',
                              }}
                            >
                              Düzenlenmiş Değer
                            </b>
                          </>
                        )}
                      </tr>
                    </th>
                  </React.Fragment>
                ))}

                <th
                  className="text-center"
                  style={{
                    width: '35px',
                  }}
                >
                  <i
                    className="fa fa-history"
                    aria-hidden="true"
                    style={{
                      pointerEvents: 'none',
                    }}
                  ></i>
                </th>
              </tr>
            </thead>
            <tbody className="tbody">
              {row?.data &&
                row.data?.rowList &&
                row.data.rowList?.map((b, i) => {
                  if (b.editable) {
                    const datas = findUniqueData(
                      b.trackingInfo[0]?.trackingInfoRow
                    );
                    const difference = findDifference(
                      b.trackingInfo[0]?.trackingInfoRow
                    );
                    const note = notes.filter(
                      (e) => e.uniqueRowName === b.uniqueName
                    )[0];

                    return (
                      isTitleVisible(row.name, b.collapsableInfo) && (
                        <React.Fragment key={i}>
                          <tr
                            style={{
                              border: '1px solid black',
                              background: specifyColor(
                                b,
                                row.data.rowList[i + 1]
                              ),
                            }}
                          >
                            <td
                              className="td-first"
                              style={{
                                background: specifyColor(
                                  b,
                                  row.data.rowList[i + 1]
                                ),

                                color:
                                  errors.validation &&
                                  errors.validation[b.uniqueName] &&
                                  'red',

                                fontWeight: specifyFontWeight(
                                  b,
                                  row.data.rowList[i + 1]
                                ),
                              }}
                            >
                              <td>
                                {b.title}
                                <i
                                  className="fa fa-pencil-square"
                                  style={{
                                    color: note && note.commentText && 'orange',
                                    marginLeft: '5px',
                                  }}
                                  title="Açıklama Ekle"
                                  aria-hidden="true"
                                  onClick={() =>
                                    showTextSwal(
                                      note
                                        ? note
                                        : {
                                            userId: user?.id,
                                            documentId: window.atob(id),
                                            uniqueRowName: b.uniqueName,
                                            commentText: '',
                                          }
                                    )
                                  }
                                />
                              </td>

                              <td>
                                {b.collapsableInfo in
                                  titlesForVisibility[row.name] && (
                                  <Button
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      width: '12px',
                                      height: '14px',
                                      alignItems: 'center',
                                      marginLeft: '5px',
                                    }}
                                    onClick={() =>
                                      handleClickOpenCloseTitles(
                                        row.name,
                                        b.collapsableInfo
                                      )
                                    }
                                    outline={true}
                                  >
                                    <i
                                      className={`fa-solid fa-arrow-${
                                        titlesForVisibility[row.name][
                                          b.collapsableInfo
                                        ] === true
                                          ? 'down'
                                          : 'up'
                                      }`}
                                      style={{
                                        fontSize: '12px',
                                      }}
                                    ></i>
                                  </Button>
                                )}
                              </td>
                            </td>

                            {b.values.map((a, j) => {
                              const { nameIndex, titleIndex, yearIndex } =
                                findChangedIndexAndCollapsibleInfo(
                                  row.name,
                                  b.uniqueName,
                                  a.year
                                );

                              return (
                                <React.Fragment key={j}>
                                  <td
                                    style={{
                                      borderRight: '1px solid black',
                                      borderBottom:
                                        row.data &&
                                        row.data.rowList &&
                                        row.data.rowList.length - 1 === i &&
                                        '1px solid black',
                                    }}
                                  >
                                    <tr
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-around',
                                      }}
                                    >
                                      <NumberFormat
                                        allowNegative={true}
                                        className="form-control"
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                        disabled
                                        value={
                                          _statement[nameIndex].data.rowList[
                                            titleIndex
                                          ].values[yearIndex].value === 0
                                            ? ''
                                            : _statement[nameIndex].data
                                                .rowList[titleIndex].values[
                                                yearIndex
                                              ].value
                                        }
                                        style={{
                                          height: '10px',
                                          textAlign: 'right',
                                          minWidth: '130px',

                                          width: !openColumns.includes(a.year)
                                            ? '130px'
                                            : '',
                                        }}
                                        decimalScale={2}
                                      />
                                      {openColumns.includes(a.year) && (
                                        <>
                                          <NumberFormat
                                            allowNegative={
                                              b.title.includes('(-)')
                                                ? false
                                                : true
                                            }
                                            className="form-control"
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                            data-title={b.uniqueName}
                                            data-year={a.year}
                                            data-name={row.name}
                                            decimalScale={2}
                                            disabled={isDisabled(
                                              b.title,
                                              analyzeType
                                            )}
                                            value={
                                              (calculatorDatas[b.uniqueName] &&
                                                calculatorDatas[b.uniqueName][
                                                  a.year
                                                ]) === 0
                                                ? ''
                                                : calculatorDatas[
                                                    b.uniqueName
                                                  ] &&
                                                  calculatorDatas[b.uniqueName][
                                                    a.year
                                                  ]
                                            }
                                            onChange={(e) => {
                                              handleCalculatorDatas(e);
                                            }}
                                            style={{
                                              marginLeft: '1em',
                                              height: '10px',
                                              minWidth: '130px',

                                              ...{
                                                ...(editableInputClassName[
                                                  `${b.uniqueName}/${a.year}`
                                                ] || {
                                                  textAlign: 'right',
                                                }),
                                              },
                                            }}
                                            onBlur={(e) => onBlur(e, 'calc')}
                                            onFocus={(e) => {
                                              setEditableInputClassName(
                                                (prev) => ({
                                                  ...prev,
                                                  [`${b.uniqueName}/${a.year}`]:
                                                    {
                                                      textAlign: 'left',
                                                    },
                                                })
                                              );
                                              if (e.target.value === '0')
                                                e.target.value = '';
                                            }}
                                            onKeyDown={(e) => {
                                              if (
                                                e.key === 'Delete' ||
                                                e.key === 'Backspace'
                                              ) {
                                                e.persist();

                                                handleCalculatorDatas(e);
                                              }
                                            }}
                                          />
                                          <NumberFormat
                                            allowNegative={
                                              b.title.includes('(-)')
                                                ? false
                                                : true
                                            }
                                            className="form-control"
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                            data-title={b.uniqueName}
                                            data-year={a.year}
                                            data-name={row.name}
                                            value={a.value === 0 ? '' : a.value}
                                            onChange={(e) => {
                                              handleChangeAktifEdit(e);
                                            }}
                                            title={a.value === 0 ? '' : a.value}
                                            disabled={isDisabled(
                                              b.title,
                                              analyzeType
                                            )}
                                            decimalScale={2}
                                            id={j}
                                            onBlur={(e) => onBlur(e)}
                                            style={{
                                              height: '10px',
                                              marginLeft: '1em',
                                              minWidth: '130px',
                                              ...{
                                                ...(editableInputClassName[
                                                  `${b.uniqueName}/${a.year}`
                                                ] || {
                                                  textAlign: 'right',
                                                }),
                                              },
                                            }}
                                            onFocus={(e) => {
                                              setEditableInputClassName(
                                                (prev) => ({
                                                  ...prev,
                                                  [`${b.uniqueName}/${a.year}`]:
                                                    {
                                                      textAlign: 'left',
                                                    },
                                                })
                                              );
                                              onFocus(
                                                e,
                                                a.value,
                                                setEditableInputClassName
                                              );
                                            }}
                                          />
                                        </>
                                      )}
                                    </tr>

                                    {datas.length > 0 && (
                                      <div
                                        style={{
                                          display: 'flex',
                                          textAlign: 'center',
                                          justifyContent: 'center',
                                        }}
                                      >
                                        {difference[j] !== 0 && (
                                          <Label
                                            style={{
                                              color:
                                                difference[j] < 0
                                                  ? 'red'
                                                  : 'green',
                                            }}
                                          >
                                            {difference[j] > 0 && '+'}
                                            {difference[j]?.toLocaleString(
                                              'tr-TR'
                                            )}
                                          </Label>
                                        )}
                                      </div>
                                    )}
                                  </td>
                                </React.Fragment>
                              );
                            })}

                            <td
                              className="min"
                              style={{
                                borderBottom:
                                  row.data &&
                                  row.data.rowList &&
                                  row.data.rowList.length - 1 === i &&
                                  '1px solid black',
                              }}
                            >
                              {datas.length > 0 && (
                                <Button
                                  outline={true}
                                  color="primary"
                                  onClick={() => {
                                    toggle(`${row.name}-${i}`, datas);
                                  }}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '10px',
                                    height: '15px',
                                  }}
                                >
                                  <i className="fas fa-eye" />
                                </Button>
                              )}
                            </td>
                          </tr>
                          <td
                            colSpan={6}
                            style={{
                              display:
                                !modal.includes(`${row.name}-${i}`) && 'none',
                            }}
                          >
                            <div className={'modalContentEnable'}>
                              <Table striped bordered hover size="sm">
                                <thead>
                                  <tr>
                                    <th>
                                      <FormattedMessage id="USER" />
                                    </th>
                                    <th>
                                      <FormattedMessage id="MODIFIED_DATE" />
                                    </th>

                                    {row?.data?.rowList[0]?.values?.map(
                                      (a, z) => (
                                        <React.Fragment key={z}>
                                          <th className="text-center">
                                            {a.year}
                                          </th>
                                        </React.Fragment>
                                      )
                                    )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {datas.map((e, i) => {
                                    return (
                                      <tr key={i}>
                                        <td> {names[e.userId]}</td>
                                        <td>{dateConverter(e.modifiedDate)}</td>
                                        {e.modifiedData.map((d, j) => (
                                          <td key={j}>
                                            {d.value?.toLocaleString('tr')}
                                          </td>
                                        ))}
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            </div>
                          </td>
                        </React.Fragment>
                      )
                    );
                  }
                })}
            </tbody>
          </Table>
        </div>
      )
    );
  };

  const handleQuickEdit = async () => {
    setLoading(true);
    try {
      const result = await api.tokenized.post(
        '/tables/statement/quickEdit/' + window.atob(id),
        statement
      );
      if (result.status === 200) {
        const errorMessage = result.data.message;
        showSwal(
          `<p>${
            typeof errorMessage === 'string'
              ? ''
              : localStorage.frayjet_frayjet_language === 'en'
                ? errorMessage.eng
                : errorMessage.tr
          }</p>`,
          '',
          intl,
          'success'
        );
        api.tokenized
          .get('/tables/statement/edit/' + window.atob(id))
          .then((a) => {
            const data_ = JSON.parse(JSON.stringify(a.data));
            setStatement(data_);
            const titles = getClosesibleTitles(data_);

            setTitlesForVisibility(titles);

            const newCalcualtor = JSON.parse(JSON.stringify(calculatorDatas));

            a.data.map((e) => {
              e.data.rowList.map((e2) => {
                const years = e2.values.map((e3) => e3.year);
                const differences = findDifference(
                  e2.trackingInfo[0]?.trackingInfoRow
                );
                newCalcualtor[e2.uniqueName] = {
                  [years[0]]: differences[0],
                  [years[1]]: differences[1],
                  [years[2]]: differences[2],
                  isDisabled: isDisabled(e2.title, analyzeType),
                };
              });
            });
            setCalculatorDatas(newCalcualtor);
          });

        api.tokenized
          .get('/tables/statement/edit/constant/' + window.atob(id))
          .then((a) => {
            _setStatement(JSON.parse(JSON.stringify(a.data)));
          });

        api.tokenized
          .get('/tables/comments/get/' + window.atob(id))
          .then(
            (a) =>
              a.data &&
              a.data.requestModel &&
              setNotes(a.data && a.data.requestModel)
          );

        const fetchData = async () => {
          try {
            const result = await api.tokenized.get(
              '/tables/statement/edit/check/' + window.atob(id)
            );

            if (result.status === 200) {
              console.log('result: ', result);
            }
          } catch (e) {
            if (e.message && e.response.data.message) {
              const errorMessage = e.response.data.message;
              showSwal(
                `<p>${
                  typeof errorMessage === 'string'
                    ? ''
                    : localStorage.frayjet_frayjet_language === 'en'
                      ? errorMessage.eng
                      : errorMessage.tr
                }</p>`,
                '',
                intl
              );
            }
          } finally {
            console.log('test');
          }
        };
        fetchData();
        update();
      }
    } catch (e) {
      const errorMessage = e.response.data.message;
      if (e.message && e.response.data.message) {
        showSwal(
          `<p>${
            typeof errorMessage === 'string'
              ? ''
              : localStorage.frayjet_frayjet_language === 'en'
                ? errorMessage.eng
                : errorMessage.tr
          }</p>`,
          '',
          intl
        );
      }
    }
    setChangedDatas([]);
    setLoading(false);
  };

  React.useEffect(() => {
    if (_statement && errorInfo) {
      const years = _statement[0]?.data?.rowList[0]?.values.map((e) => e.year);

      setOpenColumns(years);
    }
  }, [_statement, errorInfo]);
  return (
    <Breadcrumb
      title={<FormattedMessage id="EDIT" />}
      data={[
        {
          href: '/analyzes',
          name: <FormattedMessage id="PREVIOUS_ANALYZES_TITLE" />,
        },
        {
          href: `/analysis/${id}/edit`,
          name: <FormattedMessage id="EDIT" />,
        },
      ]}
    >
      <CustomForm
        submit={newSave}
        errors={errors}
        loading={loading || analyzeTypeLoading}
        done={done}
        setError={setError}
        id={'REDIRECT_SUMMARY'}
        errorMessage="Kırmızı renkli kalemlerde değişiklik yaptınız. Lütfen açıklama ekleyiniz."
        disableButton={
          aktifPasifDetection &&
          buttonIsDisabled(
            calculateNewAktifPasifDetection(
              aktifPasifDetection,
              findSumOfCalculatedDatas(changedDatas, aktifPasifDetection)
            )
          )
        }
      >
        {errorInfo && (
          <Status400_406ModalTable
            errorInfo={errorInfo}
            id={_id}
            fetchDatas={fetchDatas}
          />
        )}
        {statement.length ? (
          <Tabs defaultActiveKey={statement[0]?.name}>
            {statement
              .filter((e) => e.name !== 'Additional Items')
              .map((row, i) => (
                <Tab
                  eventKey={row.name}
                  title={<FormattedMessage id={row.name} />}
                  key={i}
                >
                  {i === 0 && (
                    <div>
                      <AktifPasifDetectionTable
                        aktifPasifDetection={aktifPasifDetection}
                        openCalculatorStatus={openColumns}
                        setOpenCalculatorStatus={setOpenColumns}
                        calculatorDatas={changedDatas}
                        analyzeType={analyzeType}
                      />
                    </div>
                  )}

                  <div
                    style={{
                      justifyContent: 'flex-end',
                      display: 'flex',
                      marginTop: '1em',
                      gap: '1em',
                    }}
                  >
                    {i == 0 && (
                      <Button onClick={handleQuickEdit} color="primary">
                        Hızlı Düzelt
                      </Button>
                    )}
                    <Button
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        handleDiffractionForTitles(row.name);
                      }}
                    >
                      {maxNumber === findLessDotCountDiffractionItem([row.name])
                        ? 'Kırılımları Kapat'
                        : `${dotCountsForDiffractionItems(row.name).findIndex((e) => e === findLessDotCountDiffractionItem([row.name])) + 1}. Kırılımı Aç`}
                    </Button>
                  </div>
                  {tableItems(row, i)}
                  {i === 0 && (
                    <div>
                      {tableItems(additionalItemData, 'additionalItems')}
                    </div>
                  )}
                </Tab>
              ))}
          </Tabs>
        ) : (
          <Alerts
            color="primary"
            className="fas fa-spin fa-spinner"
            messageId=""
          />
        )}
      </CustomForm>
    </Breadcrumb>
  );
};

export default AktifAnalysisEdit;
