import React, { useState } from 'react';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import { useAuth } from 'react-admin-base';
import { SingleFilePicker } from 'react-admin-base-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useFormSubmit, CustomForm } from '../../../Components/CustomForm';
import { useNavigate } from 'react-router-dom';
import { AsyncPaginateInput_ } from '../../../Components/Selects';
import { AsyncPaginateInput, InputField } from '../../../Components/Input';
import { Breadcrumb } from 'react-admin-base-nazox';

//will bi fixed !code duplication!

export const AktifDetection = () => {
  const [api] = useAuth();
  const [done, setDone] = useState(false);
  const navigate = useNavigate();
  const defaultDocument = {
    financialFile: null,
    year: '',
  };
  const defaultNewState = {
    sectorCode: '',
    analysisCustomerId: '',
    financialFile: null,
    year: '',
    documents: [
      {
        financialFile: null,
        year: '',
      },
    ],
  };

  const onSubmit = async () => {
    const detectionDetais = [
      ...optionsState.documents.map((a) => ({
        documentId: a.financialFile.id,
        fileExtension: a.financialFile.name.match(/\.(....?)$/)[1], // . olacaksa [1] kismi [0] olacak
        isMidtermSta: false,
        year: Number(a.year.split('-')[0]),
        month: Number(a.year.split('-')[1]),
      })),
    ];
    if (optionsState.financialFile)
      detectionDetais.push({
        documentId: optionsState.financialFile.id,
        fileExtension: optionsState.financialFile.name.match(/\.(....?)$/)[1], // . olacaksa [1] kismi [0] olacak
        isMidtermSta: true,
        year: Number(optionsState.year.split('-')[0]),
        month: Number(optionsState.year.split('-')[1]),
      });

    await api.tokenized.post('/detect/trialbalance', {
      sectorCode: optionsState.sectorCode.code,
      analysisDetail: {
        analysisCustomerId: optionsState.analysisCustomerId.value,
      },
      detectionDetails: [...detectionDetais],
    });

    setDone(true);
  };
  const validateOptions = (values) => {
    const error = {};
    if (!values.analysisCustomerId) {
      error.analysisCustomerId = 'REQUIRED';
    }
    if (!values.year && values.financialFile) {
      error.year = 'REQUIRED';
    }
    if (!values.financialFile && values.documents.length <= 1) {
      error.financialFile = 'REQUIRED';
    }
    if (!values.sectorCode?.code) {
      error.sectorCode = 'REQUIRED';
    }

    values.documents.forEach((v, index) => {
      error[index] = {};

      if (!v.financialFile) {
        error[index]['financialFile'] = 'REQUIRED';
      }
      if (!v.year) {
        error[index]['year'] = 'REQUIRED';
      }
    });

    return error;
  };
  const {
    loading,
    newSave,
    setOptionState,
    errors,
    optionsState,
    handleChangeOptions,
    handleChangeDocuments,
    handleFilePicker,
    handleChangeSector,
    setError,
  } = useFormSubmit(onSubmit, validateOptions, defaultNewState);

  const goPage = () => {
    navigate(
      '/analysis/' +
        window.btoa(optionsState.documents[0].financialFile.id) +
        '/edit'
    );
    document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  if (done) {
    setTimeout(() => {
      goPage();
    }, 2000);
  }

  React.useEffect(() => {
    if (!optionsState.financialFile)
      handleChangeOptions({ target: { value: '', name: 'year' } });
  }, [optionsState.financialFile]);
  return (
    <Breadcrumb
      title={<FormattedMessage id="DETECTION" />}
      data={[
        {
          href: '/detection/create',
          name: <FormattedMessage id="DETECTION" />,
        },
      ]}
    >
      <CustomForm
        submit={newSave}
        errors={errors}
        loading={loading}
        done={done}
        id="REDIRECT_SUMMARY"
        setError={setError}
      >
        <FormGroup className={'mt-3'}>
          <AsyncPaginateInput
            id="analysisCustomerId"
            name="analysisCustomerId"
            value={optionsState['analysisCustomerId']}
            onChange={(e) => {
              let newState;
              newState = { ...optionsState };
              if (e) {
                const { value, name } = e.target;
                newState[name] = value;
                newState['sectorCode'] = e.sector ? e.sector : '';
              } else {
                newState['analysisCustomerId'] = null;
                newState['sectorCode'] = '';
              }
              setOptionState(newState);
            }}
            api={api}
            validation={errors.validation}
            label=""
          >
            <Button
              style={{ marginLeft: '1em' }}
              size="sm"
              color="success"
              className="fas fa-plus"
              onClick={() => navigate('/customer/add')}
            />
          </AsyncPaginateInput>
        </FormGroup>
        <FormGroup className={'mt-3'}>
          <Label>
            <FormattedMessage id="SECTOR_TYPE" />
          </Label>

          <AsyncPaginateInput_
            value={optionsState.sectorCode}
            onChange={(e) => handleChangeSector(e, 'sectorCode')}
            url="/sector/get/paginate/all"
            getOptionLabel={(a) =>
              `${a.code}-${
                localStorage.frayjet_frayjet_language === 'en'
                  ? a.enName
                  : a.name
              }`
            }
            getOptionValue={(a) => <FormattedMessage id={a.code} />}
            filterResponse={(res) => res.data}
            isMulti={false}
          />

          {errors.validation && errors.validation.sectorCode && (
            <p style={{ color: 'red', opacity: 0.6, fontSize: '12px' }}>
              {<FormattedMessage id={'sectorCode'} />}{' '}
              {<FormattedMessage id={errors.validation.sectorCode} />}
            </p>
          )}
        </FormGroup>
        {optionsState &&
          optionsState.documents &&
          optionsState.documents.map((row, i) => (
            <div key={`document-${i}`}>
              <FormGroup className={'mt-3'}>
                <Row>
                  <Col sm="6">
                    <Label>
                      <FormattedMessage id="YEARLY_TRIALBALANCE" />
                    </Label>
                    <SingleFilePicker
                      accepts=".pdf,.xlsx,.png,.jpg"
                      value={row.financialFile}
                      onChange={(a) => {
                        handleFilePicker(a, i, true);
                        if (!a)
                          handleChangeDocuments(
                            { target: { value: '', name: 'year' } },
                            i
                          );
                      }}
                    />
                    {errors.validation &&
                      errors.validation[i] &&
                      errors.validation[i].financialFile && (
                        <p
                          style={{
                            color: 'red',
                            opacity: 0.6,
                            position: 'absolute',
                            fontSize: '12px',
                          }}
                        >
                          {<FormattedMessage id={'financialFile'} />}{' '}
                          {
                            <FormattedMessage
                              id={errors.validation[i]['financialFile']}
                            />
                          }
                        </p>
                      )}
                  </Col>
                  {row && row.financialFile && (
                    <Col sm="2">
                      <InputField
                        name="year"
                        id="Year"
                        value={row.year}
                        bsSize="sm"
                        type="month"
                        onChange={(e) => {
                          const value = e.currentTarget.value.split('-')[0];
                          if (
                            !value ||
                            (!optionsState.documents.find(
                              (v, index) =>
                                index !== i && v.year.split('-')[0] === value
                            ) &&
                              optionsState.year.split('-')[0] !== value)
                          ) {
                            handleChangeDocuments(e, i);
                          }
                        }}
                        max="2024-12"
                        min="2015-01"
                        validation={errors.validation && errors.validation[i]}
                      />
                    </Col>
                  )}
                </Row>
              </FormGroup>

              {row &&
                row.financialFile &&
                i === optionsState?.documents.length - 1 &&
                i < 2 && (
                  <Button
                    style={{ marginTop: '1em' }}
                    color="success"
                    type="button"
                    onClick={() => {
                      setOptionState((prev) => ({
                        ...prev,
                        documents: [...prev.documents, { ...defaultDocument }],
                      }));
                    }}
                  >
                    <i className="fas fa-plus" />{' '}
                    <FormattedMessage id="ADD_DOCUMENT" />
                  </Button>
                )}
              {optionsState.documents.length > 1 && (
                <Button
                  style={{
                    marginTop: '1em',
                    marginLeft:
                      row &&
                      row.financialFile &&
                      i === optionsState?.documents.length - 1 &&
                      i < 2 &&
                      '2em',
                  }}
                  type="button"
                  color="danger"
                  onClick={() => {
                    let newArray = optionsState.documents.filter(
                      (d, index) => index !== i
                    );
                    setOptionState((prev) => ({
                      ...prev,
                      documents: newArray,
                    }));
                  }}
                >
                  <i className="fas fa-trash" />{' '}
                  <FormattedMessage id="DELETE_DOCUMENT" />
                </Button>
              )}
            </div>
          ))}
        <hr style={{ height: '3px' }} />
        <FormGroup className={'mt-3'}>
          <Row>
            <Col sm="6">
              <Label>
                <FormattedMessage id="LAST_PERIOD_TRIALBALANCE" />
              </Label>
              <SingleFilePicker
                accepts=".pdf,.xlsx,.png,.jpg"
                value={optionsState.financialFile}
                onChange={(a) => {
                  //handlechangesector function is proper for one document too.

                  handleChangeSector(a, 'financialFile');
                }}
              />
              {errors.validation && errors.validation.financialFile && (
                <p
                  style={{
                    color: 'red',
                    opacity: 0.6,
                    position: 'absolute',
                    fontSize: '12px',
                  }}
                >
                  {<FormattedMessage id={'financialFile'} />}{' '}
                  {<FormattedMessage id={errors.validation['financialFile']} />}
                </p>
              )}
            </Col>
            {optionsState.financialFile && (
              <Col sm="2">
                <InputField
                  name="year"
                  id="Year"
                  value={optionsState.year}
                  bsSize="sm"
                  type="month"
                  onChange={(e) => {
                    const value = e.currentTarget.value.split('-')[0];
                    if (
                      !value ||
                      !optionsState.documents.find(
                        (v) => v.year.split('-')[0] === value
                      )
                    ) {
                      handleChangeOptions(e);
                    }
                  }}
                  max="2024-12"
                  min="2015-01"
                  validation={errors.validation}
                />
              </Col>
            )}
          </Row>
        </FormGroup>
      </CustomForm>
    </Breadcrumb>
  );
};
