import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import { formatNumber } from '../../../Util/formatNumber';
import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';
import { useAuth } from 'react-admin-base';
import { Loading } from '../../Loading';
import { fireSwal } from '../../SwalFire';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

const formatData = (data) => {
  const formattedData = [];

  data.map((e) => {
    e.details.map((e2) => {
      const { title, value } = e2;
      const index = formattedData.findIndex((e) => e.title === title);
      if (index > -1) {
        formattedData[index].data.push({
          value,
          year: e.year,
        });
        return;
      }

      formattedData.push({
        title,
        index: e2.index,
        data: [
          {
            value,
            year: e.year,
          },
        ],
      });
    });
  });

  return formattedData;
};

const parseData = (formattedData) => {
  const data = [];

  formattedData.forEach((item) => {
    item.data.forEach((entry) => {
      const existingEntry = data.find(
        (dataEntry) => dataEntry.year === entry.year
      );
      if (existingEntry) {
        const existingDetail = existingEntry.details.find(
          (detail) => detail.title === item.title
        );
        if (existingDetail) {
          existingDetail.value = entry.value;
          existingDetail.index = item.index;
        } else {
          existingEntry.details.push({
            title: item.title,
            value: entry.value,
            index: item.index,
          });
        }
      } else {
        data.push({
          year: entry.year,
          details: [
            {
              title: item.title,
              value: entry.value,
              index: item.index,
            },
          ],
        });
      }
    });
  });

  return data;
};

const Status400_406ModalTable_ = ({ errorInfo, id, fetchDatas }) => {
  if (!errorInfo) return null;
  const [modalVisible, setModaiVisible] = React.useState(
    errorInfo ? true : false
  );

  const message = errorInfo.message;
  const data = errorInfo.data;
  const status = errorInfo.status;
  const isStatus406 = status === 406;

  const toggle = () => !isStatus406 && setModaiVisible(!modalVisible);

  return (
    <Modal isOpen={modalVisible} toggle={toggle} size="lg">
      <ModalHeader>
        <i
          className="fa fa-exclamation-circle"
          aria-hidden="true"
          style={{
            marginRight: '5px',
          }}
        ></i>

        <span>
          {typeof message === 'string'
            ? ''
            : localStorage.frayjet_frayjet_language === 'en'
              ? message.eng
              : message.tr}
        </span>
      </ModalHeader>
      <ModalBody>
        <TableContent
          data={data}
          status={status}
          id={id}
          setModaiVisible={setModaiVisible}
          fetchDatas={fetchDatas}
        />
      </ModalBody>
    </Modal>
  );
};

const TableContent = ({ data, status, id, setModaiVisible, fetchDatas }) => {
  const [API] = useAuth();
  const formattedData = formatData(data);
  const intl = useIntl();
  const navigate = useNavigate();

  const [tableData, setTableData] = React.useState([...formattedData]);
  const [loading, setLoading] = React.useState(false);

  const submitHandler = async () => {
    const formattedData = parseData(tableData);
    try {
      setLoading(true);
      const result = await API.tokenized.post(
        'tables/statement/edit/summations/' + id,

        formattedData
      );

      if (result.status === 200 || result.status === 400) {
        setModaiVisible(false);
        fireSwal({
          background: '#90EE90',
          icon: 'success',
          text: intl.formatMessage({ id: 'SAVED' }),
        });
        navigate('/report/' + id + '/summary');
      } else throw new Error();
    } catch (e) {
      if (e.response.status === 400) {
        setModaiVisible(false);

        fireSwal({
          background: '#90EE90',
          icon: 'success',
          text: intl.formatMessage({ id: 'SAVED' }),
        });
        fetchDatas();
      } else
        fireSwal({
          background: '#F08080',
          icon: 'error',
          text: intl.formatMessage({ id: 'ERROR' }),
        });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && <Loading />}
      <Table responsive hover size="sm">
        <thead>
          <tr>
            <th>
              {' '}
              <FormattedMessage id="ACCOUND_CODE" /> |{' '}
              <FormattedMessage id="SUBTAB_4_1_TITLE1" />
            </th>
            {tableData[0].data.map((e, i) => {
              return <th key={i}>{e.year}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {tableData.map((e, i) => {
            return (
              <tr key={i}>
                <td>{e.title}</td>
                {e.data.map((e2, i2) => {
                  return (
                    <td key={i2}>
                      {status === 400 ? (
                        formatNumber(e2.value)
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            gap: '5px',
                          }}
                        >
                          <NumberFormat
                            allowNegative={true}
                            className="form-control"
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            value={
                              e2.value === null || e2.value === undefined
                                ? ''
                                : e2.value
                            }
                            style={{
                              height: '10px',
                              width: '50%',
                            }}
                            decimalScale={2}
                            onValueChange={(values) => {
                              const { floatValue } = values;
                              const newData = [...tableData];
                              newData[i].data[i2].value = floatValue;
                              setTableData(newData);
                            }}
                          />
                          <span
                            style={{
                              fontSize: '12px',
                              display: 'flex',
                            }}
                          >
                            {formattedData[i].data[i2].value !==
                              tableData[i].data[i2].value &&
                              formatNumber(formattedData[i].data[i2].value)}
                          </span>
                        </div>
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>

      {status === 406 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <button className="btn btn-primary" onClick={submitHandler}>
            <FormattedMessage id="SEND" />
          </button>
        </div>
      )}
    </div>
  );
};

export const Status400_406ModalTable = React.memo(Status400_406ModalTable_);
